.icon {
  flex-shrink: 0;
  position: relative;
  background-color: $brand-primary;
  border: 3px solid $white;
  box-shadow: 0 0 0 2px $brand-secondary;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  margin: 3px auto 30px;

  @include laptop {
    margin: 3px 36px 0 0;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.underline {
  border-bottom: 6px double #2e2e2e;
  padding-bottom: 15px;
  margin-bottom: 6px;
}

.why-choose-panel {
  &.panel {
    @include hd {
      padding: 60px 0 81px;
    }
  }

  .h1 {
    margin-bottom: 4px;
  }

  .row {
    margin-top: 52px;
  }

  .feature-col:nth-of-type(3n+2) .feature {
    @include wide {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .feature-col:nth-of-type(3n+3) .feature {
    @include wide {
      margin-right: 0;
      margin-left: auto;
    }
  }

  .feature {
    text-align: center;

    @include laptop {
      text-align: left;
    }

    @include hd {
      width: 93%;
    }
  }
}

.service-panels {
  .panel {
    position: relative;
    z-index: 0;

    @include laptop {
      display: flex;
      align-items: center;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;

      @include laptop {
        transform: skewX(-14deg);
      }
    }

    .image-container {
      margin-bottom: 30px;

      @include laptop {
        margin-bottom: 0;
        position: absolute;
        width: 51%;
        transform: skewX(-14deg);
        overflow: hidden;
      }

      .image {
        background-size: cover;
        background-position: center;
        min-height: 250px;

        @include tablet {
          min-height: 400px;
        }

        @include laptop {
          position: absolute;
          top: 0;
          bottom: 0;
          transform: skewX(14deg);
        }
      }
    }

    .content {
      text-align: center;

      @include laptop {
        text-align: left;
      }

      .h1 {
        margin-bottom: 18px;
      }

      .h1::before {
        @include laptop {
          content: '';
          display: inline-block;
          width: 6px;
          height: 28px;
          margin-right: 17px;
        }
      }

      p {
        line-height: 1.43;
        letter-spacing: 0.09px;
      }

      .btn {
        margin-top: 7px;

        span {
          display: none;

          @include desktop {
            display: inline;
          }
        }
      }
    }
  }

  .panel:nth-of-type(odd) {
    @include laptop {
      min-height: 537px;
    }

    &::before {
      background-image: url('../images/black-bg.webp');
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      @include laptop {
        right: 42%;
        left: -100vw;
      }
    }

    .image-container {
      top: 36px;
      bottom: 36px;
      right: -63px;

      .image {
        right: 63px;
        left: -63px;
      }
    }

    .content {
      .h1,
      p {
        color: $white;
      }

      .h1::before {
        background-color: $brand-secondary;
      }
    }
  }

  .panel:nth-of-type(even) {
    @include laptop {
      min-height: 557px;
    }

    &::before {
      @include laptop {
        background-color: $brand-primary;
        top: 10px;
        right: 57%;
        bottom: 10px;
        left: -100vw;
      }
    }

    .row {
      @include laptop {
        flex-direction: row-reverse;
      }
    }

    .image-container {
      top: 46px;
      bottom: 46px;
      left: -63px;

      .image {
        right: -63px;
        left: 63px;
      }
    }

    .content {
      .h1::before {
        background-color: $brand-primary;
      }
    }
  }
}
