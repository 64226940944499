//
//  Media Query Mixins
//  ---------------------------------------------

// based on boostrap media-breakpoint-up mixin
@mixin screen($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  }

  @else {
    @content;
  }
}

// based on boostrap media-breakpoint-down mixin
@mixin screen-max($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  }

  @else {
    @content;
  }
}

// Legacy mixins
@mixin mobile {
  @media (min-width: #{$width_mobile}) {
    @content;
  }
}

@mixin phablet {
  @media (min-width: #{$width_phablet}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$width_tablet}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$width_laptop}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$width_desktop}) {
    @content;
  }
}

@mixin wide {
  @media (min-width: #{$width_wide}) {
    @content;
  }
}

@mixin hd {
  @media (min-width: #{$width_hd}) {
    @content;
  }
}

//
//  Typography Mixins
//  ---------------------------------------------

// Font style mixin
@mixin text-style($font-sizes, $font-family: inherit, $font-weight: inherit, $color: inherit, $line-height: inherit) {
  font-family: #{$font-family};
  font-weight: #{$font-weight};
  line-height: #{$line-height};
  color: #{$color};

  @each $width, $size in $font-sizes {
    @if $width == 'base' {
      font-size: $size;
    }

    @else {
      @include screen($width) {
        font-size: $size;
      }
    }
  }
}

// Smooth fonts in -webkit browsers and Firefox
@mixin smooth-fonts() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Letter spacing mixin
@mixin letter-spacing($letter-spacing: 0) {
  letter-spacing: calc(#{$letter-spacing} * 0.001em);
}

// Font Awesome Pseudo Element
@mixin fa-pseudo($icon, $position: 'before', $weight: inherit, $size: inherit, $color: inherit) {
  &::#{$position} {
    content: $icon;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-size: $size;
    font-weight: $weight;
    color: $color;
    font-family: 'Font Awesome 6 Pro', sans-serif;
  }
}

//Underline on hover style
@mixin link-underline($color: $brand-primary, $weight: inherit, $text: inherit) {
  position: relative;
  font-weight: $weight;
  text-transform: $text;
  color: $color;

  &:hover {
    color: $color;
  }

  &::before {
    content: '';
    background-color: $color;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: 0.3s;
  }

  &:hover::before {
    width: 0;
  }
}

//
//  Element Positioning Mixins
//  ---------------------------------------------

@mixin flex-sticky-footer() {
  html {
    display: flex;
  }

  body.sideNavBody { // any body class found on every page can be used here, this keeps styles from effecting the Wordpress editor
    min-height: 100vh;
    width: 100%;
    max-width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
  }

  .wrap {
    flex: 1 1 auto;
  }
}

@mixin section-padding($padding-map: $section-padding) {
  @each $width, $padding in $padding-map {
    @if $width == 'base' {
      padding: $padding;
    }

    @else {
      @include screen($width) {
        padding: $padding;
      }
    }
  }
}

// Aspect ratio mixin
@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Center absolute
@mixin center-absolute($top: true, $left: true) {
  position: absolute;

  @if $top == true and $left == true {
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }

  @else if $top == true {
    transform: translateY(-50%);
    top: 50%;
  }

  @else if $left == true {
    transform: translateX(-50%);
    left: 50%;
  }
}

// Stretch element to fit constraints
// requires position: relative; parent
@mixin stretch($offset: 0) {
  position: absolute;
  top: #{$offset};
  right: #{$offset};
  left: #{$offset};
  bottom: #{$offset};
}

// Flexbox mixin - prevents most IE 11 issues
@mixin flex($grow: 0, $shrink: 0, $basis: 100%) {
  flex: #{$grow} #{$shrink} #{$basis};
  width: #{$basis};
  max-width: #{$basis};
}

// Padding Offset Mixin
@mixin padding-offset($parent: 0, $selector: '', $child: $parent) {
  @if type-of($parent) == 'map' {
    @each $width, $offset in $parent {
      @if $width == 'base' {
        margin-left: -#{$offset};
        margin-right: -#{$offset};

        #{$selector} {
          padding-left: #{$offset};
          padding-right: #{$offset};
        }
      }

      @else {
        @include screen($width) {
          margin-left: -#{$offset};
          margin-right: -#{$offset};

          #{$selector} {
            padding-left: #{$offset};
            padding-right: #{$offset};
          }
        }
      }
    }
  }

  @else {
    margin-left: -#{$parent};
    margin-right: -#{$parent};

    #{$selector} {
      padding-left: #{$child};
      padding-right: #{$child};
    }
  }
}

//
//  Styling and Animation Mixins
//  ---------------------------------------------

@mixin links-transition($property: (all), $duration: $transition-duration, $style: $transition-style) {
  will-change: $property;
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $style;
}

@mixin object-fit($position: center) {
  position: relative;
  overflow: hidden;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: $position;
    font-family: 'object-fit: cover; object-position: #{$position};', sans-serif;
  }
}

@mixin inset-shadow($direction: left, $offset: 7px, $blur: 9px, $color: rgba(0, 0, 0, 0.4)) {
  @if $direction == left {
    box-shadow: inset #{$offset} 0 $blur -#{$offset} $color;
  }

  @else if $direction == right {
    box-shadow: inset -#{$offset} 0 $blur -#{$offset} $color;
  }

  @else if $direction == top {
    box-shadow: inset 0 #{$offset} $blur -#{$offset} $color;
  }

  @else if $direction == bottom {
    box-shadow: inset 0 -#{$offset} $blur -#{$offset} $color;
  }
}
