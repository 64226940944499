.hero-panel,
.prefooter-panel {
  position: relative;
  background-color: #1d1d1d;

  > .container {
    position: relative;
    z-index: 1;
  }
}

.hero-panel {
  @include laptop {
    margin-top: -147px;
  }

  > .container {
    @include laptop {
      padding: 260px 0 110px;
    }

    @include desktop {
      padding: 300px 0 170px;
    }

    @include wide {
      padding: 310px 0 170px;
    }
  }
}

body.home .hero-panel > .container {
  @include hd {
    padding: 366px 0 212px;
  }
}

.prefooter-panel {
  > .container {
    position: relative;
    z-index: 1;

    @include laptop {
      padding: 140px 0 160px;
    }

    @include wide {
      padding: 201px 0 220px;
    }
  }
}

.hero,
.prefooter {
  background-size: cover;
  height: 250px;

  @include tablet {
    height: 400px;
  }

  @include laptop {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.hero-content {
  position: relative;
  padding: 30px 0 35px;
  text-align: center;

  @include tablet {
    padding: 40px 0 45px;
  }

  @include laptop {
    background-color: rgba(#1d1d1d, 0.9);
    width: 580px;
    margin-left: 15px;
    text-align: left;
  }

  &::before {
    @include laptop {
      content: '';
      background-color: $brand-primary;
      height: 6px;
      position: absolute;
      right: 31px;
      bottom: -3px;
      left: 31px;
    }
  }

  .line_1,
  .line_2 {
    color: $white;
  }

  .line_1 {
    font-size: 14px;
    margin-bottom: 14px;
    line-height: 1.4;

    @include laptop {
      line-height: 1.1;
    }

    @include wide {
      font-size: 18px;
    }
  }

  .line_2 {
    font-size: 33px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.1;
    position: relative;

    @include wide {
      font-size: 42px;
    }

    @include hd {
      font-size: 47.5px;
    }

    &::before {
      @include laptop {
        content: '';
        background-color: $brand-secondary;
        width: 6px;
        position: absolute;
      }
    }
  }

  .cta-container {
    @include laptop {
      position: absolute;
      bottom: -22px;
      left: 10px;
      transform: translateX(0);
    }

    .btn {
      margin-top: 10px;

      &:not(:last-of-type) {
        @include tablet {
          margin-right: 20px;
        }
      }
    }
  }
}

.hero-panel .hero-content {
  @include laptop {
    padding: 41px 50px 43px 80px;
  }

  @include wide {
    width: 795px;
  }

  .line_1 {
    letter-spacing: 0.5px;
  }

  .line_2 {
    @include hd {
      font-size: 47.5px;
    }

    &::before {
      height: 54px;
      top: 0;
      left: -29px;
    }
  }
}

.prefooter-panel .hero-content {
  @include laptop {
    padding: 31px 50px 47px 75px;
  }

  @include wide {
    width: 754px;
  }

  .line_2 {
    @include hd {
      font-size: 45px;
    }

    &::before {
      height: 38px;
      top: 7px;
      left: -24px;
    }
  }
}

.strapline {
  position: relative;
  z-index: 1;
  padding: 24px 0 22px;

  @include tablet {
    padding: 34px 0 32px;
  }

  @include wide {
    padding: 54px 0 50px;
  }

  &::before {
    content: '';
    background-image: url('../images/black-bg.webp');
    opacity: 0.9;
    z-index: -1;

    @include stretch();

    @include laptop {
      background-color: rgba($black, 0.2);
      background-blend-mode: overlay;
    }
  }

  p {
    color: #eae5dd;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.75;
    text-align: center;
    letter-spacing: -0.1px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
