a[href^="tel:"] {
  color: $body-color;
}

.google-map {
  position: relative;
  overflow: hidden;
  flex-grow: 1;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 400px;
    border: 0;
    position: relative;

    @include laptop {
      min-height: 300px;
    }
  }
}

.search-form {
  display: flex;
  flex-wrap: wrap;

  label {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

a.page-numbers,
span.page-numbers {
  margin-right: 5px;
}

.page-header h1 {
  text-align: center;
  margin-bottom: 25px;

  @include tablet {
    margin-bottom: 35px;
  }
}

.page-header.no-hero {
  margin-top: 30px;
}
