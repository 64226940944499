#skip-navigation {
  position: absolute;
  display: block;
  background: $white;
  color: $brand-primary;
  padding: 15px;
  text-align: center;
  transform: translateY(-101%);
  transition: transform 0.25s ease-in-out;
  z-index: 10;

  &:focus {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;
  }
}

#modal {
  overflow: scroll;
  background-color: rgba($black, 0.5);

  .modal-dialog {
    @include tablet {
      max-width: 75%;
    }

    @include hd {
      max-width: 50%;
    }
  }
}

.brand {
  display: inline-block;

  img {
    max-width: 165px;

    @include desktop {
      max-width: 230px;
    }
  }
}

.banner {
  position: relative;
  z-index: 2;

  .brand {
    position: absolute;
    z-index: 2;
    margin-top: 10px;
  }

  .nav-top,
  .nav-primary {
    position: relative;
    z-index: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100vw;
      width: 200vw;
      z-index: -1;
    }

    > li:not(:last-of-type) {
      margin-right: 30px;

      @include wide {
        margin-right: 60px;
      }
    }

    a {
      display: block;
      color: $white;
      font-weight: 500;
      letter-spacing: 0.8px;
      font-size: 15px;

      @include links-transition();

      @include wide {
        font-size: 16px;
      }
    }

    > li:hover > a:not(.phone) {
      color: $brand-secondary;
    }
  }

  .nav-top {
    position: relative;
    z-index: 1;

    &::before {
      background-color: #111;
    }

    > li:not(:last-of-type) {
      @include hd {
        margin-right: 100px;
      }
    }

    a {
      padding: 15px 0 11px;

      @include desktop {
        padding: 26px 0 19px;
      }
    }
  }

  .nav-top.has-hero {
    &::before {
      @include laptop {
        background-color: rgba(#222, 0.7);
      }
    }
  }

  .nav-primary {
    &::before {
      background-image: url('../images/black-bg.webp');

      @include laptop {
        background-color: rgba($black, 0.2);
        background-blend-mode: overlay;
      }
    }

    > li:not(:last-of-type) {
      @include hd {
        margin-right: 98px;
      }
    }

    > li > a {
      text-transform: uppercase;
      padding: 30px 0 24px;
    }

    li.menu-item-has-children:last-of-type .sub-menu {
      right: 0;
    }
  }
}

li.menu-item-has-children.open .sub-menu {
  visibility: visible;
  opacity: 1;
}

.sub-menu {
  visibility: hidden;
  opacity: 0;
  list-style: none;
  z-index: 1;
  padding: 10px 0;

  @include links-transition((visibility, opacity, top));

  a {
    padding: 10px 20px !important;

    &:hover {
      background-color: darken($brand-primary, 9%);
    }
  }
}

.banner .sub-menu {
  box-shadow: 0 3px 5px rgba($black, 0.44);
  background-color: $brand-primary;
  position: absolute;
  max-width: 320px;
  min-width: 180px;
}

.banner,
.sideNav {
  li.menu-item-has-children > a {
    @extend .down-caret;

    &::after {
      margin-left: 9px;
      position: relative;
    }
  }

  li.menu-item-has-children.open > a {
    @extend .up-caret;
  }
}
