.btn {
  border-radius: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.7px;
  padding: 13px 24px 9px;
  width: 100%;

  @include tablet {
    width: auto;
  }

  @include wide {
    font-size: 16px;
    padding: 13px 28px 9px;
  }
}

.btn-primary {
  background-color: $brand-primary;

  &:hover {
    background-color: darken($brand-primary, 9%);
  }
}

.search-submit {
  width: auto;

  @extend .btn;
  @extend .btn-primary;
}
