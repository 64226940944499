/*
* Global block styles
*/

//color palette
.has-black-background-color {
  background-color: #000 !important;
}

.has-black-color,
.has-black-color *:not(a) {
  color: #000;
}

.has-cyan-bluish-gray-background-color {
  background-color: #abb8c3 !important;
}

.has-cyan-bluish-gray-color,
.has-cyan-bluish-gray-color *:not(a) {
  color: #abb8c3;
}

.has-white-background-color {
  background-color: #fff !important;
}

.has-white-color,
.has-white-color *:not(a) {
  color: #fff;
}

.has-pale-pink-background-color {
  background-color: #f78da7 !important;
}

.has-pale-pink-color,
.has-pale-pink-color *:not(a) {
  color: #f78da7;
  border-color: #f78da7;
}

.has-vivid-red-background-color {
  background-color: #cf2e2e !important;
}

.has-vivid-red-color,
.has-vivid-red-color *:not(a) {
  color: #cf2e2e;
}

.has-luminous-vivid-orange-background-color {
  background-color: #ff6900 !important;
}

.has-luminous-vivid-orange-color,
.has-luminous-vivid-orange-color *:not(a) {
  color: #ff6900;
}

.has-luminous-vivid-amber-background-color {
  background-color: #fcb900 !important;
}

.has-luminous-vivid-amber-color,
.has-luminous-vivid-amber-color *:not(a) {
  color: #fcb900;
}

.has-light-green-cyan-background-color {
  background-color: #7bdcb5 !important;
}

.has-light-green-cyan-color,
.has-light-green-cyan-color *:not(a) {
  color: #7bdcb5;
}

.has-vivid-green-cyan-background-color {
  background-color: #00d084 !important;
}

.has-vivid-green-cyan-color,
.has-vivid-green-cyan-color *:not(a) {
  color: #00d084;
}

.has-pale-cyan-blue-background-color {
  background-color: #8ed1fc !important;
}

.has-pale-cyan-blue-color,
.has-pale-cyan-blue-color *:not(a) {
  color: #8ed1fc;
}

.has-vivid-cyan-blue-background-color {
  background-color: #0693e3 !important;
}

.has-vivid-cyan-blue-color,
.has-vivid-cyan-blue-color *:not(a) {
  color: #0693e3;
}

.has-vivid-purple-background-color {
  background-color: #9b51e0 !important;
}

.has-vivid-purple-color,
.has-vivid-cyan-blue-color *:not(a) {
  color: #9b51e0;
}

//paragraph styles
.has-small-font-size {
  font-size: 13px;
}

.has-medium-font-size {
  font-size: 20px;
}

.has-large-font-size {
  font-size: 36px;
}

.has-x-large-font-size {
  font-size: 42px;
}

.has-drop-cap::first-letter {
  color: $brand-primary;
  float: left;
  font-family: $heading-font;
  font-size: 75px;
  line-height: 45px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
}

//alignment
.has-text-align-left {
  text-align: left;
}

.has-text-align-center,
.aligncenter {
  text-align: center;
}

.has-text-align-right {
  text-align: right;
}

/*
* Block: Quote
*/
.wp-block-quote {
  margin: 0 0 1rem 2rem;

  p::before,
  p::after {
    content: '"';
  }

  p {
    color: inherit;
    font-style: italic;
  }

  cite::before {
    content: '-';
  }
}

/*
* Block: Table
*/
.wp-block-table {
  overflow: auto;

  td,
  th {
    padding: 10px 15px;
  }
}

.wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
  background-color: #f0f0f0;
}

.wp-block-table:not(.is-style-stripes) {
  td,
  th {
    border: 1px solid;
  }
}

.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%;
}

.wp-block-table .has-fixed-layout td,
.wp-block-table .has-fixed-layout th {
  word-break: break-word;
}

.wp-block-table figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}

/*
* Block: Image
*/
.wp-block-image {
  figure {
    display: table;
  }

  figcaption {
    display: table-caption;
    caption-side: bottom;
    margin-top: 1rem;
    font-size: 14px;
  }
}

.wp-block-image.is-style-rounded img {
  border-radius: 50%;
}

/*
* Block: Audio
*/
.wp-block-audio {
  audio {
    width: 100%;
  }

  figcaption {
    font-size: 13px;
  }
}

/*
* Block: File
*/
.wp-block-file {
  margin-bottom: 0.5rem;

  > a:first-child {
    display: block;
  }

  .wp-block-file__button {
    background: transparent;
    color: #5d5852;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0.5rem;

    &::before {
      content: '–';
    }
  }
}

/*
* Block: Media & Text
*/
.wp-block-media-text {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1rem;
}

.wp-block-media-text.is-stacked-on-mobile {
  display: block;

  @include tablet {
    display: grid;
  }
}

.wp-block-media-text.is-image-fill figure {
  background-size: cover;
  min-height: 250px;

  @include tablet {
    min-height: 400px;
  }

  @include laptop {
    min-height: 250px;
  }

  img {
    display: none;
  }
}

.wp-block-media-text.has-media-on-the-right {
  grid-auto-flow: dense;

  figure {
    grid-column: 2;
  }
}

.wp-block-media-text.is-vertically-aligned-center {
  align-items: center;
}

.wp-block-media-text.is-vertically-aligned-bottom {
  align-items: end;
}

/*
* Block: Columns
*/
.wp-block-columns {
  display: block;
  gap: 3rem;

  @include laptop {
    display: flex;
  }
}

.wp-block-columns.is-not-stacked-on-mobile {
  display: flex;
}

.wp-block-column {
  flex: 1 1 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.wp-block-column.is-vertically-aligned-center {
  justify-content: center;
}

.wp-block-column.is-vertically-aligned-bottom {
  justify-content: flex-end;
}

/*
* Block: Buttons
*/
.wp-block-button {
  margin-bottom: 1rem;
  display: inline-block;

  @include tablet {
    margin-right: 1rem;
  }

  a {
    @extend .btn;
    @extend .btn-secondary;
  }
}

/*
* Block: Group
*/
.wp-block-group .wp-block-group__inner-container {
  overflow: auto;
}

/*
* Block: Heading
*/
.wp-block-heading {
  padding-top: 15px;
}

/*
* Block: Lightgallery
*/
.lightgallery {
  .gallery-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
    border: 1px solid #cecece;

    @include aspect-ratio(1.3, 1);

    img {
      display: none;
    }
  }
}

.lightgallery.block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-bottom: 1rem;

  @include laptop {
    grid-template-columns: repeat(4, 1fr);
  }
}

.image-overlay {
  position: relative;

  &::after,
  i {
    opacity: 0;

    @include links-transition(all, 0.3s, ease-in-out);
  }

  &::after {
    content: '';
    background-color: rgba($brand-primary, 0.7);

    @include stretch;
  }

  i {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 300;
    color: $white;
    font-size: 1.5em;
  }

  &:hover {
    &::after {
      opacity: 0.6;
    }

    i {
      opacity: 1;
    }
  }
}
