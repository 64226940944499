#review-us {
  i.fas.fa-star {
    color: #ffc200;
    font-size: 20px;
  }

  .nav-link i {
    color: #9f9f9f;
    font-size: 100px;
    margin-bottom: 30px;

    @include links-transition();

    @include tablet {
      font-size: 150px;
    }
  }

  .nav-link.active i {
    color: $brand-primary;
  }

  .message {
    background-color: $brand-primary;
    border-radius: 30px;
    padding: 20px 15px;
    margin-bottom: 2rem;
    position: relative;
    color: $white;
    font-weight: 500;
    display: inline-block;

    @include tablet {
      padding: 30px;
    }
  }

  .tab-content .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 150px;
    background-color: #fefefe;
    box-shadow: 0 1px 5px rgba(#000, 0.4);
    border-radius: 5px;
    padding: 30px;

    @include links-transition();

    &:hover {
      filter: brightness(0.95);
    }

    img {
      max-height: 100%;
    }
  }
}
