.slider-nav {
  position: relative;
  display: inline-block;
  padding: 0 23px;

  .slick-dots {
    position: static;
    width: auto;
    display: flex !important;
    align-items: center;

    li,
    button,
    button::before {
      width: auto;
      height: auto;
    }

    li {
      margin: 0 5px;
    }

    button::before {
      content: '\f2f8';
      font-family: 'Font Awesome 6 Pro', sans-serif;
      font-size: 25px;
      font-weight: 700;
      position: relative;
      opacity: 1;
      color: #232323;
    }

    .slick-active button::before {
      color: $brand-primary;
      opacity: 1;
    }
  }

  .slick-arrow {
    height: auto;
    width: auto;

    &::before {
      font-family: 'Font Awesome 6 Pro', sans-serif;
      font-weight: 700;
      font-size: 26px;
      color: #232323;
      opacity: 1;
    }

    &.slick-prev {
      left: -15px;

      &::before {
        content: '\f0a8';
      }
    }

    &.slick-next {
      right: -15px;

      &::before {
        content: '\f0a9';
      }
    }
  }
}

body:not(.home) .testimonials-panel.panel {
  padding-top: 0;
}

.testimonials-panel {
  &.panel {
    @include hd {
      padding: 60px 0 76px;
    }
  }

  .testimonials-carousel {
    margin-top: 30px;

    @include laptop {
      margin-top: 55px;
      margin-bottom: 42px !important;
    }
  }

  .testimonial {
    @include tablet {
      padding: 0 12px;
    }
  }
}

footer.footer-panel {
  background-image: url('../images/black-bg.webp');
  padding-bottom: 17px;
  position: relative;
  z-index: 1;

  p,
  span,
  a {
    color: $white;
  }

  a:not(.phone):hover {
    color: $brand-secondary;
  }

  > .container {
    @include hd {
      max-width: 1645px;
    }
  }

  .brand {
    margin-top: 30px;
    margin-bottom: 25px;

    @include laptop {
      margin-top: -70px;
    }
  }

  .contact-info {
    margin-bottom: 20px;

    span {
      display: block;
      line-height: 2;

      @include tablet {
        display: inline;
        line-height: $body-line-height;
      }
    }

    span:not(:last-of-type)::after {
      @include tablet {
        content: '|';
        margin: 0 20px;
      }
    }
  }

  ul.nav {
    li {
      @include phablet {
        margin: 0 15px 15px;
      }

      @include laptop {
        margin: 0 25px 15px;
      }

      @include hd {
        margin: 0 39px 15px;
      }
    }

    a {
      line-height: 3;
      letter-spacing: 0.1px;

      @include phablet {
        line-height: $body-line-height;
      }
    }
  }

  .sub-menu {
    display: none;
  }
}

footer.copyright {
  padding: 13px 0 10px;
  background-color: #2c3033;

  *,
  a:hover {
    color: $white;
    font-size: 13px;
  }

  a i {
    display: none;
  }
}
